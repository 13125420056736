// zabezpieczenie stron które są dostępne tylko zalogowanych licytantów lub zalogowanych komorników/pracowników kancelarii komorniczej

import { useUserAuthStore } from '~coreStores/user-auth.store';

import { saveRedirectUrl } from '~coreUtils/save-login-redirect';

export default defineNuxtRouteMiddleware((to) => {
  const runtimeConfig = useRuntimeConfig();

  const userAuthStore = useUserAuthStore();
  const { getUserData } = storeToRefs(userAuthStore);

  // jeśli licytant nie jest zalogowany to zapisz adres do któego próbował się dostaćprzekieruj go na stronę logowania
  if (!getUserData.value) {
    saveRedirectUrl(to.fullPath);

    const path = `${runtimeConfig.public.baseUrl}${runtimeConfig.public.loginServer}/mc2authorize?response_type=code&client_id=e-auctions_app&redirect_uri=${runtimeConfig.public.appUrl}/${runtimeConfig.public.redirectUriForLoginAsBidder}`;

    return navigateTo(path, { external: true });
  }
});
